var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "my-charging-point-details-edit" } },
    [
      _c("HeaderNavigation", {
        attrs: { title: "Mes points de recharge - Détails" }
      }),
      _c("b-container", { staticClass: "main-container" }, [
        _c("h1", [
          _vm._v(" Point de recharge #" + _vm._s(_vm.chargingPoint.id) + " "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_c("span", [_vm._v("×")])]
          )
        ]),
        _c(
          "section",
          [
            _c("header", [
              _vm._v(" Modifiez les informations de votre point de recharge. ")
            ]),
            _c(
              "b-form",
              {
                ref: "form",
                attrs: { validated: _vm.validated, novalidate: "" }
              },
              [
                _c("b-form-group", { attrs: { label: "Adresse" } }, [
                  _c("span", { staticClass: "text-uppercase" }, [
                    _vm._v(_vm._s(_vm.chargingPoint.address))
                  ])
                ]),
                _c("b-form-group", { attrs: { label: "Visibilité" } }, [
                  _c("span", { staticClass: "text-uppercase" }, [
                    _vm._v(
                      _vm._s(
                        _vm.chargingPoint.private
                          ? "Mes invités"
                          : "Tout le monde"
                      )
                    )
                  ])
                ]),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "Prix pour 30 minutes",
                      description: _vm.priceAdviceDescription
                    }
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "number",
                        min: "0.5",
                        step: "0.1",
                        required: ""
                      },
                      model: {
                        value: _vm.chargingPoint.price,
                        callback: function($$v) {
                          _vm.$set(_vm.chargingPoint, "price", $$v)
                        },
                        expression: "chargingPoint.price"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { attrs: { label: "Détails complémentaires" } },
                  [
                    _c("b-form-input", {
                      model: {
                        value: _vm.chargingPoint.context.details,
                        callback: function($$v) {
                          _vm.$set(_vm.chargingPoint.context, "details", $$v)
                        },
                        expression: "chargingPoint.context.details"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "footer",
              { staticClass: "text-right" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "loader",
                        rawName: "v-loader.disable",
                        value: _vm.busy,
                        expression: "busy",
                        modifiers: { disable: true }
                      }
                    ],
                    staticClass: "btn-validate",
                    attrs: { variant: "dark" },
                    on: { click: _vm.validate }
                  },
                  [_vm._v(" Valider")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="my-charging-point-details-edit">
    <HeaderNavigation title="Mes points de recharge - Détails" />
    <b-container class="main-container">
      <h1>
        Point de recharge #{{ chargingPoint.id }}
        <button class="close" @click="$router.back()">
          <span>&times;</span>
        </button>
      </h1>
      <section>
        <header>
          Modifiez les informations de votre point de recharge.
        </header>
        <b-form ref="form" :validated="validated" novalidate>
          <b-form-group label="Adresse">
            <span class="text-uppercase">{{ chargingPoint.address }}</span>
          </b-form-group>
          <b-form-group label="Visibilité">
            <span class="text-uppercase">{{
              chargingPoint.private ? 'Mes invités' : 'Tout le monde'
            }}</span>
          </b-form-group>
          <b-form-group
            label="Prix pour 30 minutes"
            :description="priceAdviceDescription"
          >
            <b-form-input
              type="number"
              v-model="chargingPoint.price"
              min="0.5"
              step="0.1"
              required
            />
          </b-form-group>
          <b-form-group label="Détails complémentaires">
            <b-form-input v-model="chargingPoint.context.details" />
          </b-form-group>
        </b-form>
        <footer class="text-right">
          <b-button
            v-loader.disable="busy"
            variant="dark"
            class="btn-validate"
            @click="validate"
          >
            Valider</b-button
          >
        </footer>
      </section>
    </b-container>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import { Routes } from '@/router.js'

export default {
  name: 'my-charging-point-details-edit',
  props: {
    id: { type: String, required: true }
  },
  components: { HeaderNavigation },
  data() {
    return {
      chargingPoint: {
        context: {}
      },
      validated: false,
      busy: false
    }
  },
  computed: {
    priceAdvice() {
      switch (this.chargingPoint.type) {
        case 'TE':
          return 0.5
        case 'T2':
          switch (Number(this.chargingPoint.power)) {
            case 3.7:
              return 1
            case 7.4:
              return 2
            case 11:
              return 2.5
            case 22:
              return 5
          }
      }

      return 0
    },
    priceAdviceDescription() {
      return this.priceAdvice
        ? `Prix indicatif pour ce type de point de recharge : ${this.priceAdvice} € / 30 min`
        : ''
    }
  },
  async mounted() {
    let cp = this.$store.getters.getChargingPoint(this.id)

    if (!cp) {
      cp = await this.$apiService.getCurrentUserChargingPointAsync(this.id)
    }

    this.chargingPoint = JSON.parse(JSON.stringify(cp))
  },
  methods: {
    validate() {
      const isValid = this.$refs.form.checkValidity()
      this.validated = true

      if (isValid) {
        this.busy = true

        this.$apiService
          .postUserChargingPointAsync(this.chargingPoint)
          .then(() => {
            this.$store.commit('setChargingPoint', this.chargingPoint)

            this.$router.push({
              ...Routes.MY_CHARGING_POINT,
              params: { id: this.id }
            })
          })
          .catch(err =>
            console.error(
              'Error while validating charging point availability: ',
              err
            )
          )
          .finally(() => (this.busy = false))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-charging-point-details-edit {
  height: 100vh;
  background-color: #f8f8f8;

  h1 {
    margin-bottom: 36px;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;
    text-transform: uppercase;

    button.close {
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: #152536;
      opacity: 1;
    }
  }

  header {
    margin: 15px 0;
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $text-light;
  }

  form {
    fieldset {
      /deep/ legend {
        text-transform: uppercase;
      }
    }
  }

  .btn-validate {
    width: 98px;
  }
}
</style>
